@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
    :root {
        --sidebar-background: 0 0% 98%;
        --sidebar-foreground: 240 5.3% 26.1%;
        --sidebar-primary: 240 5.9% 10%;
        --sidebar-primary-foreground: 0 0% 98%;
        --sidebar-accent: 240 4.8% 95.9%;
        --sidebar-accent-foreground: 240 5.9% 10%;
        --sidebar-border: 220 13% 91%;
        --sidebar-ring: 217.2 91.2% 59.8%;
    }
}
@layer base {
    * {
        @apply border-border;
    }
    body {
        @apply bg-background text-foreground;
    }
}

html {
    scroll-behavior: smooth;
}

.circle-picker {
    width: 100% !important;
    justify-content: center;
}

.chrome-picker {
    width: 100% !important;
    box-shadow: none !important;
    border-radius: 0.5rem !important;
    overflow: hidden !important;
}



@layer base {
  * {
    @apply border-border outline-ring/50;
    }
  body {
    @apply bg-background text-foreground;
    }
}
